/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import { withStyles } from "@material-ui/core";

import logo from 'assets/img/rhm-logo.png';

import { grayColor } from "assets/jss/material-kit-pro-react.js";

const brandStyle = theme => ({
  container: {
    padding: "23px 0 0 0",
    textTransform: "uppercase",
    fontSize: "0.75rem",
    fontWeight: 500,
    color: grayColor[0],
    lineHeight: "1.5rem",
    minWidth: "325px",
    [theme.breakpoints.down("xs")]: {
      minWidth: "inherit"
    }
  },
  image: {
    float: "left",
    height: "75px",
    margin: "-12px 18px 0 0"
  },
  textSpan: {
    display: "inline-block",
    whiteSpace: "normal",
    width: "200px",
    [theme.breakpoints.down("xs")]: {
      display: "none"
    }
  }
});

function Brand({ ...props }) {
  const { classes } = props;
  return (
    <div className={classes.container}>
      <img className={classes.image} src={logo} alt=""></img>
      <span className={classes.textSpan}>RON HUTCHCRAFT MINISTRIES PRESENTS...</span>
    </div>
  );
}

Brand.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(brandStyle)(Brand);
