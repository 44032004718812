export const isBrowser = () => typeof window !== "undefined"

export const getUser = () =>
  isBrowser() && window.localStorage.getItem("prUser")
    ? JSON.parse(window.localStorage.getItem("prUser"))
    : {}

const setUser = user => window.localStorage.setItem("prUser", JSON.stringify(user))

export const handleLogin = ({ names, password }) => {
  if (names && password && password.toUpperCase() === `RHM2020`) {
    setUser({
      names: names,
      preWatchComplete: false
    });
    return true;
  }
  return false;
}

export const completePreWatch = () => {
  var user = getUser();
  user.preWatchComplete = true;
  setUser(user);
}

export const isLoggedIn = () => {
  const user = getUser()
  return !!user.names
}

export const isPreWatchComplete = () => {
  const user = getUser()
  return (!!user.names && user.preWatchComplete);
}

export const logout = callback => {
  setUser({})
  if(callback) {
    callback()
  }
}
